<template>
  <v-dialog
    persistent
    transition="dialog-bottom-transition"
    class="b-dialog"
    content-class="b-dialog-question"
    :max-width="320"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal"
  >
    <v-card class="b-dialog-question-card">
      <div class="b-dialog-question-card--header">
        <v-icon color="primary" size="40"> attach_file </v-icon>
      </div>
      <div class="b-dialog-question-card--body">
        <ChatFiles :showHeader="false" />
      </div>
      <div class="b-dialog-question-card--footer">
        <BaseButton
          outlined
          color="primary"
          title="Voltar"
          @click="closeModal"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    ChatFiles: () => import('@/components/pages/supports/chat-files')
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    }
  },

  methods: {
    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>
